const OptionalSizes = ({minusSizes, plusSizes, selectedSmartSize, optionalSize, selectOptionalSizeHandler}) => {
  return (
    <ul className="flex mt-2">Sizes:&nbsp;
      {/* minus sizes */}
      {[...new Set(minusSizes.map(item => item.rimDiameter))].sort().map(rim => (
        rim < selectedSmartSize.rimDiameter &&
          <li className="mr-2" key={'minus'+rim}>
            <button className={"inline-block bg-blue-500 hover:bg-blue-700 rounded py-1 px-3 text-white text-sm " + (rim === optionalSize ? "ring-4" : "")}
                    onClick={selectOptionalSizeHandler} 
                    data-rimdiameter={rim}>{rim}" {rim - selectedSmartSize.rimDiameter}</button>
          </li>
      ))}
      {/* plus sizes */}
      {[...new Set(plusSizes.map(item => item.rimDiameter))].sort().map(rim => (
      <li className="mr-2" key={'plus'+rim}>
        <button className={"inline-block bg-blue-500 hover:bg-blue-700 rounded py-1 px-3 text-white text-sm " + (rim === optionalSize ? "ring-4" : "")}
                onClick={selectOptionalSizeHandler} 
                data-rimdiameter={rim}>{rim}" +{rim - selectedSmartSize.rimDiameter}</button>
      </li>
      ))}
    </ul>
  )
};

export default OptionalSizes;