import { useState, useEffect, useRef } from 'react'

import { http } from './lib/http'
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import Slider from "react-slick";
import Select from './components/Select'
import ProductDisplay from './components/ProductDisplay'
import OptionalSizes from './components/OptionalSizes'

import vehiclePlaceholder from './assets/images/placeholder.png';
import noResultsFound from './assets/images/no-results-found.png';

function App() {
  const sliderRef = useRef(null);

  const [years, setYears] = useState([])
  const [loadingYear, setLoadingYear] = useState(false);
  const [selectedYear, setSelectedYear] = useState({})

  const [makes, setMakes] = useState([])
  const [loadingMake, setLoadingMake] = useState(false);
  const [selectedMake, setSelectedMake] = useState({})

  const [models, setModels] = useState([])
  const [loadingModel, setLoadingModel] = useState(false);
  const [selectedModel, setSelectedModel] = useState({})

  const [submodels, setSubmodels] = useState([])
  const [loadingSubmodel, setLoadingSubmodel] = useState(false);
  const [selectedSubmodel, setSelectedSubmodel] = useState({})

  const [smartSizes, setSmartSizes] = useState([])
  const [loadingSmartSize, setLoadingSmartSize] = useState(false);
  const [selectedSmartSize, setSelectedSmartSize] = useState({})

  const [wheels, setWheels] = useState({})
  const [loadingWheels, setLoadingWheels] = useState(false);
  const [selectedProductId, setSelectedProductId] = useState(0)
  const [selectedProduct, setSelectedProduct] = useState({})

  const [visualizations, setVisualizations] = useState({})
  const [vehicleImage, setVehicleImage] = useState(vehiclePlaceholder)
  const [vehicleImageIndex, setVehicleImageIndex] = useState(0)

  const [bodyConfig, setBodyConfig] = useState([])
  const [selectedBodyConfig, setSelectedBodyConfig] = useState('')

  const [vehicle, setVehicle] = useState({})

  const [optionalSize, setOptionalSize] = useState(0)

  const { key: selectedYearKey } = selectedYear;
  const { key: selectedMakeKey } = selectedMake;
  const { key: selectedModelKey } = selectedModel;
  const { fmk: selectedFmk } = selectedSmartSize;

  const selectYearHandler = (event) => {
    const key = +event.target.value;
    const obj = years.find(y => y.key === key);
    setSelectedYear(obj);
    setSelectedMake({});
    setMakes([])
    setSelectedModel({});
    setModels([])
    setSelectedSubmodel({});
    setSubmodels([])
    setSmartSizes([])
  }

  const selectMakeHandler = (event) => {
    const key = +event.target.value;
    const obj = makes.find(y => y.key === key);
    setSelectedMake(obj);
    setSelectedModel({});
    setModels([])
    setSelectedSubmodel({});
    setSubmodels([])
    setSmartSizes([])
  }

  const selectModelHandler = (event) => {
    const key = +event.target.value;
    const obj = models.find(y => y.key === key);
    setSelectedModel(obj);
    setSelectedSubmodel({});
    setSubmodels([])
    setSmartSizes([])
  }

  const selectSubmodelHandler = (event) => {
    const key = event.target.value;
    const obj = submodels.find(y => y.smartDescription === key);
    setSelectedSubmodel(obj);
    setSmartSizes([])
  }

  const selectSmartSizeHandler = (event) => {
    const fmk = +event.target.value;
    const obj = smartSizes.find(y => y.fmk === fmk);
    setOptionalSize(0); // reset selected optional size back to zero
    setSelectedSmartSize(obj);
  }

  const selectProductHandler = (event) => {
    console.log("Product selected handler..")
    const productId = +event.target.dataset.id
    setSelectedProductId(productId)
    const product = wheels.find(item => item.wheelAtlas.wheelAtlasId === productId)
    setSelectedProduct(product)
    setVehicleImage(visualizations[productId][vehicleImageIndex].url)
  }

  const selectVehicleColorHandler = (event) => {
    console.log("Color selected handler")
    const colorUrl = event.target.getAttribute("data-url")
    const imageIndex = visualizations[selectedProductId].findIndex(image => image.url === colorUrl)
    setVehicleImageIndex(imageIndex)
    setVehicleImage(colorUrl)
  }

  const selectBodyConfigHandler = (event) => {
    console.log("Body Config selected handler")
    const bodyConfig = event.target.value;
    const imageIndex = visualizations[selectedProductId].findIndex(image => image.extraNotes === bodyConfig)
    setVehicleImageIndex(imageIndex);
    setVehicleImage(visualizations[selectedProductId][imageIndex].url);
    setSelectedBodyConfig(bodyConfig)
  }

  const selectOptionalSizeHandler = (event) => {
    const rimDiameter = +event.target.dataset.rimdiameter
    console.log(`Optional size: ${rimDiameter}`)
    setOptionalSize(rimDiameter);
  }

  // Get Years Effect
  useEffect(() => {
    console.log("Get Years effect")
    setLoadingYear(true);
    http('vehicles/years')
      .then(response => response.json())
      .then(data => {
        setYears(data.result);
        setLoadingYear(false);
      });
  }, []);

  // Get Makes Effect
  useEffect(() => {
    if (typeof selectedYearKey !== 'undefined') {
      console.log("Get Makes effect")
      setLoadingMake(true);
      http(`vehicles/makes?YearId=${selectedYearKey}`)
        .then(response => response.json())
        .then(data => {
          setMakes(data.result);
          setLoadingMake(false);
        });
    }
  }, [selectedYearKey]);

  // Get Model Effect
  useEffect(() => {
    if (typeof selectedYearKey !== 'undefined' && 
        typeof selectedMakeKey !== 'undefined') {
      console.log("Get Models effect")
      setLoadingModel(true);
      http(`vehicles/models?YearId=${selectedYearKey}&MakeId=${selectedMakeKey}`)
        .then(response => response.json())
        .then(data => {
          setModels(data.result);
          setLoadingModel(false);
        });
    }
  }, [selectedYearKey, selectedMakeKey]);

  // Get Submodel Effect
  useEffect(() => {
    if (typeof selectedYearKey !== 'undefined' && 
        typeof selectedMakeKey !== 'undefined' && 
        typeof selectedModelKey !== 'undefined') {

      console.log("Get Smart Submodels effect")

      setLoadingSubmodel(true);

      let url = `vehicles/smartsubmodels?YearId=${selectedYearKey}`
      url += `&MakeId=${selectedMakeKey}`
      url += `&ModelId=${selectedModelKey}&ProductType=wheel`
      http(url)
        .then(response => response.json())
        .then(data => {
          setSubmodels(data.result);
          setLoadingSubmodel(false);
        });
    }
  }, [selectedYearKey, selectedMakeKey, selectedModelKey]);

  // Get Smart Sizes Effect
  useEffect(() => {
    if (typeof selectedYearKey !== 'undefined' && 
        typeof selectedMakeKey !== 'undefined' && 
        typeof selectedModelKey !== 'undefined' && 
        typeof selectedSubmodel.smartDescription !== 'undefined') {

      console.log("Get Smart Sizes effect")
      setLoadingSmartSize(true);

      let url = `vehicles/smartsizes?YearId=${selectedYearKey}`
      url += `&MakeId=${selectedMakeKey}`
      url += `&ModelId=${selectedModelKey}`
      url += `${selectedSubmodel.vcdB_SubmodelID ? `&SubmodelId=${selectedSubmodel.vcdB_SubmodelID}` : ''}`;
      url += `${selectedSubmodel.vcdB_BodyTypeID ? `&BodyTypeId=${selectedSubmodel.vcdB_BodyTypeID}` : ''}`;
      url += `${selectedSubmodel.vcdB_DriveTypeID ? `&DriveTypeId=${selectedSubmodel.vcdB_DriveTypeID}` : ''}`;
      url += `${selectedSubmodel.customNote ? `&CustomNote=${selectedSubmodel.customNote}` : ''}`;
      url += `&ProductType=wheel`;
      http(url)
        .then(response => response.json())
        .then(data => {
          setSmartSizes(data.result);
          setLoadingSmartSize(false);
        });
    }
  }, [selectedYearKey, selectedMakeKey, selectedModelKey, selectedSubmodel]);

  // Get OESizeByFMK Effect
  useEffect(() => {
    // The number of products we'll grab from OESizeByFMK endpoint (current max: 200)
    const num_results = 200;
    if (typeof selectedFmk !== 'undefined' ) {

      console.log("Get OESizeByFMK effect")

      setLoadingWheels(true);
      setWheels({});

      http(`vehicles/${selectedFmk}`)
        .then(response => response.json())
        .then(data => {
          if (data.errors) {
            throw new Error("No vehicle found")
          }
          setVehicle(data.result);
        }).catch(error => console.error(`Get Vehicle: ${error}`));

      let url = `retailers/wheels/OESizeByFMK?FactoryOEProductsOnly=false&IncludeVisualizations=true&MustInclude=visualization&fmk=${selectedFmk}`
      url += `&limit=${num_results}&offset=0`
      http(url)
        .then(response => response.json())
        .then(data => {
          setLoadingWheels(false);
          if (data.errors) {
            throw new Error("No wheels were found")
          }
          // Only allow items that have both a visualizations array with length > 0 && wheelAtlas.standardImages.length > 0
          let wheels = data.result.items.filter(item => item.visualizations.length > 0 && item.wheelAtlas.standardImages.length > 0)
       
          let visualizations = {}
          wheels.forEach(item => visualizations[item.wheelAtlas.wheelAtlasId] = item.visualizations)

          const initialProductId = wheels[0].wheelAtlas.wheelAtlasId;
          setWheels(wheels);
          setVisualizations(visualizations);
          setSelectedProductId(initialProductId);
          const product = wheels.find(item => item.wheelAtlas.wheelAtlasId === initialProductId)
          setSelectedProduct(product)
          sliderRef.current.slickGoTo(0)

          // Populate the body config data set, we'll filter the visualizations with this
          const initialBodyConfig = [...new Set(visualizations[initialProductId].map(viz => viz.extraNotes))].sort()
          setBodyConfig(initialBodyConfig);
          setSelectedBodyConfig(initialBodyConfig[0])

          // Initialize the selected vehicle image using our initialBodyConfig
          const initialImageIndex = visualizations[initialProductId].findIndex(img => img.extraNotes === initialBodyConfig[0])
          setVehicleImageIndex(initialImageIndex);
          setVehicleImage(visualizations[initialProductId][initialImageIndex].url);
        }).catch(error => {
          console.error(`Get Wheels: ${error}`)
          setVisualizations({})
          setBodyConfig([])
          setVehicleImage(noResultsFound)
        });
    }
  }, [selectedFmk]);

  // Get AllSizesByFMK Effect
  useEffect(() => {
    if (optionalSize > 0) {
      console.log(`Get AllSizesByFMK effect: ${optionalSize} | ${selectedFmk}`)

      setLoadingWheels(true);
      setWheels({});

      let url = `retailers/wheels/AllSizesByFMK?FactoryOEProductsOnly=false&IncludeVisualizations=true&MustInclude=visualization&`
      url += `fmk=${selectedFmk}&rimDiameters=${optionalSize}&limit=200&offset=0`

      http(url)
        .then(response => response.json())
        .then(data => {
          setLoadingWheels(false);
          if (data.errors) {
            throw new Error("No results were found")
          }
          let wheels = data.result.items.filter(item => item.visualizations.length > 0 && item.wheelAtlas.standardImages.length > 0)
       
          let visualizations = {}
          wheels.forEach(item => visualizations[item.wheelAtlas.wheelAtlasId] = item.visualizations)

          const initialProductId = wheels[0].wheelAtlas.wheelAtlasId;
          setWheels(wheels);
          setVisualizations(visualizations);
          setSelectedProductId(initialProductId);
          const product = wheels.find(item => item.wheelAtlas.wheelAtlasId === initialProductId)
          setSelectedProduct(product)
          sliderRef.current.slickGoTo(0)

          // Populate the body config data set, we'll filter the visualizations with this
          const initialBodyConfig = [...new Set(visualizations[initialProductId].map(viz => viz.extraNotes))].sort()
          setBodyConfig(initialBodyConfig);
          setSelectedBodyConfig(initialBodyConfig[0])

          // Initialize the selected vehicle image using our initialBodyConfig
          const initialImageIndex = visualizations[initialProductId].findIndex(img => img.extraNotes === initialBodyConfig[0])
          setVehicleImageIndex(initialImageIndex);
          setVehicleImage(visualizations[initialProductId][initialImageIndex].url);
        }).catch(error => {
          console.error(`AllSizesByFMK Error: ${error}`)
          setVisualizations({})
          setBodyConfig([])
          setVehicleImage(noResultsFound)
        });
    }
  }, [selectedFmk, optionalSize]);

  // React Slick Carousel Settings
  const slickSettings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 6,
    swipeToSlide: true,
    afterChange: function(index) {
      console.log(
        `Slider Changed to: ${index + 1}`
      );
    }
  };

  return (
    <div className="App">

      <div className="grid grid-cols-8 gap-1 px-2 pt-1">
        
        {/* Year */}
        <div className="col-span-1">
          <Select items={years} onChangeHandler={selectYearHandler} loading={loadingYear} label={"Year"}/>
        </div>

        {/* Make */}
        <div className="col-span-2">
          <Select items={makes} onChangeHandler={selectMakeHandler} loading={loadingMake} label={"Make"}/>
        </div>

        {/* Model */}
        <div className="col-span-2">
          <Select items={models} onChangeHandler={selectModelHandler} loading={loadingModel} label={"Model"}/>
        </div>

        {/* SubModel */}
        <div className="col-span-2">
          <select 
            onChange={selectSubmodelHandler} 
            className={`
              ${loadingSubmodel ? 'animate-pulse ring ring-indigo-200 ring-opacity-50 ' : ''}
              flex
              w-full
              mt-1
              mr-1
              rounded-md
              border-gray-300
              shadow-sm
              focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50`}>
            <option>Submodel</option>
            {submodels.map(item => <option key={item.smartDescription} value={item.smartDescription}>{item.smartDescription}</option>)}
          </select>
        </div>

        {/* OE Size (SmartSizes) */}
        <div className="col-span-1">
          <select 
            onChange={selectSmartSizeHandler} 
            className={`
              ${loadingSmartSize ? 'animate-pulse ring ring-indigo-200 ring-opacity-50 ' : ''}
              flex
              w-full
              mt-1
              mr-1
              rounded-md
              border-gray-300
              shadow-sm
              focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50`}>
            <option>OE Size</option>
            {smartSizes.map(item => <option key={item.fmk} value={item.fmk}>{item.rimSize}</option>)}
          </select>
        </div>
      </div>

      {/* ************* Optional Sizes && Loading Spinner ******************** */}

      <div className="h-12 px-2"> 
        <div className="flex">
          {/* OPTIONAL SIZES */}
          {Object.keys(vehicle).length > 0 && 
            <OptionalSizes minusSizes={vehicle.vehicleChassis?.vehicleMinusSizes} 
                           plusSizes={vehicle.vehicleChassis?.vehiclePlusSizes}
                           selectedSmartSize={selectedSmartSize}
                           optionalSize={optionalSize}
                           selectOptionalSizeHandler={selectOptionalSizeHandler} />
          }
          {/* END OPTIONAL SIZES */}
          {/* Loading Wheels */}
          <div className="flex-initial w-12">
            <svg className={loadingWheels ? 'animate-spin h-5 w-5 ml-3 mt-3 text-gray-500' : 'hidden'} viewBox="0 0 24 24" fill="none">
              <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"></circle>
              <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
            </svg>
          </div>
          {/* ENd Loading  */}
        </div>
      </div>

      {/* ********** Vehicle Image **************** */}

      {/* Vehicle Image */}
      <img className="mx-auto mb-6" src={vehicleImage} alt="" onLoad={() => console.log('Vehicle Image Loaded')} />

      {/* ******** Selected Product && Body Config / Color Swatch ****************** */}

      <div className="grid grid-cols-2 grid-rows-2 mx-8">
        <div className="row-span-2 self-center">
          {/* Display Selected Product */}
          {Object.keys(selectedProduct).length > 0 && 
            <ProductDisplay selectedProduct={selectedProduct}/>
          }
        </div>
        <div className="justify-self-end">

          {/* Body Config Selector */}
          {bodyConfig.length > 0 && <select
            onChange={selectBodyConfigHandler} 
            className={`
              my-1
              rounded-md
              border-gray-300
              shadow-sm
              focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50`}>
            {bodyConfig.map(item => <option key={item} value={item}>{item}</option>)}
          </select>}
        </div>
        <div className="justify-self-end">
          {/* Color Swatches */}
          {visualizations[selectedProductId] && <div className="swatch-wrapper">
            {visualizations[selectedProductId].map((item, index) => (
              item.extraNotes === selectedBodyConfig &&
                <Tippy content={item.colorName} key={selectedProductId + ":" + index}>
                  <img src={'https://iconimagestorage.blob.core.windows.net/public-access/swatch/' + item.colorFileName} alt={item.colorName} data-url={item.url} 
                    className={"color-swatch " + (item.url === vehicleImage ? "ring-4" : "")}
                    onClick={selectVehicleColorHandler} 
                  />
                </Tippy>
              )
            )}
            </div>//end swatch wrapper
          }
        </div>
      </div>

      {/* ********** Product Carousel **************** */}

      {/* React Slick - Product Carousel */}
      <div className="mx-6"> 
        <Slider ref={sliderRef} {...slickSettings}>
          {wheels.length > 0 && wheels.map((item,index) => (
              <div className="p-2" key={item.wheelAtlas.wheelAtlasId + ":" + index}>
                <button onClick={selectProductHandler} 
                        data-id={item.wheelAtlas.wheelAtlasId} 
                        className={"border-gray-500 hover:border-gray-700 border-solid border-2 p-2 rounded " + (item.wheelAtlas.wheelAtlasId === selectedProductId ? "ring-4" : "")}>
                  <img data-id={item.wheelAtlas.wheelAtlasId} src={item.wheelAtlas.standardImages[0]} alt=""/>
                </button>
              </div>
            ))
          }
        </Slider>
      </div>
      
    </div>
  );
}
export default App;
