const Select = (props) => {
  return (
    <select 
      onChange={props.onChangeHandler}
      className={`
        ${props.loading ? 'animate-pulse ring ring-indigo-200 ring-opacity-50 ' : ''}
        flex
        w-full
        mt-1
        mr-1
        rounded-md
        border-gray-300
        shadow-sm
        focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50`}>
      <option>{props.label}</option>
      {props.items.map(item => <option key={item.key} value={item.key}>{item.value}</option>)}
    </select>
  )
};

export default Select;
