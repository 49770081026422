const ProductDisplay = ({selectedProduct}) => {
  return (
    <div>
      <p className="text-xl">{selectedProduct.wheelAtlas?.brandName}</p>
      <p className="text-2xl font-bold">{selectedProduct.wheelAtlas.modelName}</p>
      <p className="text-sm">{selectedProduct.wheelAtlas.finish}</p>
      <p className="font-semibold">{selectedProduct.wheelAtlas?.size}, {selectedProduct.wheelAtlas?.boltPattern1}, {selectedProduct.wheelAtlas?.hubbore}, {selectedProduct.wheelAtlas?.offset}</p>
    </div>
  )
};

export default ProductDisplay;